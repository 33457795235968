import request from '@/plugins/axios'

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) => request.get('/shop.shop/lists', { params })

// 添加商城
export const apiShopAdd = (params: any) => request.post('/shop.shop/add', params)

// 商城详情
export const apiShopDetail = (params: any) => request.get('/shop.shop/detail', { params })

// 编辑商城
export const apiShopEdit = (params: any) => request.post('/shop.shop/edit', params)

// 删除商城
export const apiShopDelete = (params: any) => request.post('/shop.shop/delete', params)

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) => request.post('/shop.shop/changeSuperAdmin', params)

// 修改商城状态
export const apiShopSwitchStatus = (params: any) => request.post('/shop.shop/switchStatus', params)

// 历史套餐
export const apisetMealLogLists = (params: any) => request.get('/shop.shop/setMealLogLists', { params })

// 修改商城备注
export const apiChangeRemark = (params: any) => request.post('/shop.shop/changeRemark', params)
/** E 商城列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) => request.get('/shop.set_meal/lists', { params })

// 添加套餐
export const apiSetMealAdd = (params: any) => request.post('/shop.set_meal/add', params)

// 套餐详情
export const apiSetMealDetail = (params: any) => request.get('/shop.set_meal/detail', { params })

// 编辑套餐
export const apiSetMealEdit = (params: any) => request.post('/shop.set_meal/edit', params)

// 删除套餐
export const apiSetMealDelete = (params: any) => request.post('/shop.set_meal/del', params)

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) => request.post('/shop.set_meal/status', params)

export const apiMealModule = () => request.get('/shop.set_meal/getMealModule')
/** E 套餐列表 **/

// 应用类型
export const apiGetApplicationList = () => request.get('/shop.shop/getApplicationList')
// 支付宝小程序版本列表
export const apiAlipayVersionList = (params: any) => request.get('/alipayVersion/list', { params })
// 获取已经发版的小程序版本号
export const apiGetAlipayVersionList = () => request.get('/alipayVersion/getVersion')
// 添加版本
export const apiAlipayVersionAdd = (params: any) => request.post('/alipayVersion/add', params)
// 商家小程序版本
export const apiAlipayShopVersionList = (params: any) => request.post('/alipayVersion/shopVersionList', params)
// 添加商家小程序
export const apiAlipayShopVersionAdd = (params: any) => request.post('/alipayVersion/addShopVersion', params)
